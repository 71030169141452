<template>
  <div class="w-full">
    <tab-layout-router
      :route="'patient'"
      :tabs="[
        { key: 'reservations', label: 'Mis reservas' },
        { key: 'preconsulta', label: 'Primera consulta' },
        { key: 'profile', label: 'Editar datos' },
      ]"
    />
  </div>
</template>

<script>
import TabLayoutRouter from '@/components/dashboard/TabLayoutRouter.vue';

export default {
  name: 'Patient',
  components: {
    'tab-layout-router': TabLayoutRouter,
  },
};
</script>
